.mapboxgl-ctrl-group button {
  width: 40px !important;
  height: 40px !important;
  padding: 4px !important;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-position: center;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url(/assets/icon-control-plus.svg) !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url(/assets/icon-control-minus.svg) !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
  background-image: url(/assets/icon-control-north.svg) !important;
  background-position: center;
}
